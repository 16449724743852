import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl
} from "reactstrap";
import Img from "gatsby-image";
import BarExpandPlain from "../img/bar-expand-plain"

const HeroCarousel = props => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === props.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? props.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = props.items.map((item, i) => {
    return (
      <CarouselItem className="header--home"
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={`${item.src}${i}`}
      >
        <Img
          className="header__image"
          fluid={item.image.imageFile.childImageSharp.fluid}
          alt={item.altText}
        />
      </CarouselItem>
    );
  });

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous} className="hero_carousel">
      {slides}
      <Container fluid={true} className="px-0">
        <Row style={{marginTop: `-30px`}}>
        <Col xs={{size: 8}} sm={{size: 7}} md={{size: 6}}>
          <BarExpandPlain class="bar-expand-home" width="110"/>

        </Col>
          <Col xs={{size: 4}} sm={{size: 5}} md={{size: 6}}>
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Col>
        </Row>
      </Container>
    </Carousel>
  );
};

export default HeroCarousel;
