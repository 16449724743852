export default [
	{
    color: 'yellow',
		word:
		' '
	},
	{
    color: 'darkred',
		word:
		' '
	},
	{
    color: 'darkblue',
		word: ' '
	},
	{
    color: 'blue',
		word:
		'honesty'
	},
	{
    color: 'purple',
		word:
		'dependability'
	},
	{
    color: 'chartreuse',
		word:
		'progressiveness'
	},
	{
    color: 'lightcoral',
		word:
		'commitment'
	},
	{
    color: 'tomato',
		word:
		'reputation'
	},
	{color: 'whitesmoke',
		word:
		'experience'
	},
	{color: 'gray',
		word:
		'strength'
	},
	{color: 'lightpurple',
		word:
		'transparency'
	},
	{color: 'lightgreen',
		word:
		'knowledge'
	},
	{
    color: 'lightyellow',
		word:
		' '
	},
	{
    color: 'lightred',
		word:
		' '
	}
];
