import React, { useState } from "react";
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from "../components/seo";
import { Container, Row, Col } from 'reactstrap'
import HeroCarousel from "../components/carousel/hero-carousel"
import Quotes from "../components/quotes"
import TableBox from "../components/home/tablebox"
import { Parallax, Background } from "react-parallax";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";
import FadeTextThree from "../components/home/fadetextthree"
import DoubleBox from "../components/home/doubleblocks"

export const query = graphql`
  query {
    wpgraphql {
      page(id: "cG9zdDo2") {
        acf_page_meta {
        metadescription
        metatitle
      }
        acf_home_fields {
          heroCarousel {
            image {
              altText
              sourceUrl
              imageFile {
                childImageSharp {
                  fluid(maxWidth: 1900, quality: 90) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }

          sectionOneHead
          sectionOneText
          sectionThreeHead
          sectionTwoHead
          sectionTwoText
        }
      }
    }
  }
`
const Home = ({ data, location }) => {
  const page = data.wpgraphql.page
  const insideStyles = {
    padding: 20,
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  }
  const apartStyles = {
    padding: 20,
    position: 'absolute',
    top: '50%',
    left: '26%',
    transform: 'translate(-50%,-50%)'
  }
  const image1 = '/images/backgrounds/home-portfolioCTA-bkgnd-min.jpg'
  const image2 = '/images/backgrounds/home-affordable-housing_bak.jpg'

  const [on, toggle] = useState(false)

  const fadein = useSpring({
    opacity: on ? 1 : 0,
    from: { opacity: 0, transform: "translate3d(0,50px,0)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate3d(0,0,0px)" : "translate3d(0,50px,0)"
    },
    config: { tension: 100 }
  });
  
  return (
    <Layout navlocation={location.pathname}>
    <SEO
          title={data.wpgraphql.page.acf_page_meta.metatitle}
          description={data.wpgraphql.page.acf_page_meta.metadescription}
        />
      <Waypoint
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      <Row>

        <Col md={{ size: 12 }} className="px-0 container__home">
          <div style={{position: `relative`}}>
            {page.acf_home_fields.heroCarousel != null && (
              <HeroCarousel items={page.acf_home_fields.heroCarousel} />
            )}
          </div>
          <animated.div style={fadein}>
            <h1 className="heading1">Building and Reviving <span style={{fontWeight: `500`}}>Housing for Life.</span></h1>
          </animated.div>
        </Col>
      </Row>
      <Row className="home__card">
        <Col md={{ offset: 1, size: 10}} lg={{ offset: 2, size: 8}} className="px-md-2 px-lg-0">
          <section className="home__standards">
            <h2>{ page.acf_home_fields.sectionOneHead } </h2>
            <p dangerouslySetInnerHTML={{ __html: page.acf_home_fields.sectionOneText }}/>
          </section>
        </Col>
      </Row>
      <Parallax bgImage={image2} strength={300}>
        <div style={{ height: 320 }}>
          <Row className="home__card_affordable">
            <Col className="affordable_wrapper pr-0 mr-0">
              <Container fluid={true} className="px-0 mx-0">
                <Row>
                  <Col md={{ size: 5 }} lg={{ size: 7 }} className="pr-0 mr-0 d-none d-md-block">
                  &nbsp;</Col>
                  <Col md={{ size: 7 }} lg={{ size: 5 }} className="pr-0">
                    <div className="bg-primary block-green">&nbsp;</div>
                  </Col>
                </Row>
                <Row>
                  <Col md={{ offset: 5, size: 7 }} lg={{ offset: 7, size: 4 }}>
                    <section className="home__affordable">
                      <p><b>{ page.acf_home_fields.sectionTwoHead }</b> { page.acf_home_fields.sectionTwoText }</p>
                    </section>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </div>
      </Parallax>

      <Row className="home__excellence">
        <Col md={{ size: 12 }} className="pl-0">
          <h2>Three Decades of Excellence & Innovation.</h2>
        </Col>
        <TableBox/>
      </Row>


      <Row className="parallax__card_setsApart">
        <Col className="px-0 pb-3 text-center">
          <Parallax 
        //  bgImage={image3}
        //  bgImageStyle={{height: '800px', maxWidth: '2400px', opacity: '.5'}}
          strength={-500}
        >
          <Background className="custom-bg">
          <FadeTextThree/>
          </Background>
            <div style={{ height: 450 }}>
              <div style={apartStyles} className="apartHead">
                <h3>What Sets Us Apart</h3>
              </div>
            </div>
          </Parallax>
        </Col>
      </Row>
      
<DoubleBox/>

      <Row className="parallax__card_california">
        <Col className="px-0 text-center">
          <Parallax bgImage={image1} strength={300}>
            <div style={{ height: 320 }}>
              <div style={insideStyles}>
                <h2 className="">OUR CALIFORNIA PROJECTS</h2>
                <br/>
                <Link className="btn btn-outline-info" to={`/portfolio`}>
                Portfolio
                </Link>
              </div>
            </div>
          </Parallax>
        </Col>
      </Row>


<Quotes id="cG9zdDo0NTk=" />

    </Layout>
  )
}

export default Home
