import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { animated, useSpring, useTrail, useChain } from "react-spring";
import { Waypoint } from "react-waypoint";

const TableBox = () => {
  const data = useStaticQuery(graphql`
    query BoxQuery {
      wpgraphql {
        page(id: "cG9zdDo2") {
          acf_home_fields {
            sectionThreeTable {
              sectionThreeNumber
              sectionThreeSubhead
              sectionThreeText
            }

          }
        }
      }
    }
  `);

  const boxes = data.wpgraphql.page.acf_home_fields.sectionThreeTable;

  const [on, toggle] = useState(false);

  const springRef = useRef();
  const spring = useSpring({
    ref: springRef,
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: on ? 1 : 0, transform: on ? "scale(1)" : "scale(0.9)" },
    config: { duration: 300, friction: 40 }
  });
  const springRef2 = useRef();
  const spring2 = useSpring({
    ref: springRef2,
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: on ? 1 : 0, transform: on ? "scale(1)" : "scale(0.9)" },
    config: { duration: 300, friction: 40 }
  });
  const springRef3 = useRef();
  const spring3 = useSpring({
    ref: springRef3,
    from: { opacity: 0, transform: "scale(0.9)" },
    to: { opacity: on ? 1 : 0, transform: on ? "scale(1)" : "scale(0.9)" },
    config: { duration: 300, friction: 40 }
  });

  const trailRef = useRef();
  const trail = useTrail(boxes.length, {
    ref: trailRef,
    from: { opacity: 0, transform: "translate3d(0,10px,0)" },
    to: {
      opacity: on ? 1 : 0,
      transform: on ? "translate3d(0,0,0px)" : "translate3d(0,10px,0)"
    },
    config: { friction: 25 }
  });

  useChain(
    on
      ? [trailRef, springRef, springRef2, springRef3]
      : [springRef3, springRef2, springRef, trailRef],
    [0.5, on ? 1 : 5, 1.1, 1.2]
  );

  // This will orchestrate the two animations above, comment the last arg and it creates a sequence
  //  useChain(open ? [springRef, transRef] : [transRef, springRef], [0, open ? 0.1 : 0.6])

  return (
    <>
      <Waypoint
        bottomOffset="12%"
        onEnter={() => {
          if (!on) toggle(true);
        }}
      />
      {trail.map(({ ...animation }, index) => (
        <animated.div
          style={{ ...animation }}
          key={index}
          className={`col-md-6 col-lg-3 table__excellence px-2 table__excellence-cell${index}`}
        >
          <animated.h3 style={{ ...spring }} className="display-2">
            {boxes[index].sectionThreeNumber}
          </animated.h3>
          <animated.h5 style={{ ...spring2 }}>
            {boxes[index].sectionThreeSubhead}
          </animated.h5>
          <animated.p style={{ ...spring3 }}>
            {boxes[index].sectionThreeText}
          </animated.p>
        </animated.div>
      ))}
    </>
  );
};

export default TableBox;