import React, {useState} from "react";
import { animated, useSpring } from "react-spring";
import { Waypoint } from "react-waypoint";

const BarExpandPlain = (props) => {
  const [on, toggle] = useState(false);
  const barexpand = useSpring({
    width: on ? `${props.width}%` : `0%`,
    from: { width: `0%` },
    to: {
      width: on ? `${props.width}%` : `0%`,
    },
    config: { duration: 800 }
  })
    
  return (
    <>
    <Waypoint
    topOffset="0"
      onEnter={() => {
        if (!on) toggle(true);
      }}
    />
    <h2 className={props.class}><animated.span style={barexpand} className="block-green"></animated.span></h2>
    </>
  )
}

export default BarExpandPlain
